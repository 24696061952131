import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";

const ZEV = () => {
  const { t } = useTranslation();
  return (
    <div className="about-area ptb-70">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="about-img">
              <StaticImage
                src="../../assets/images/home-page/Website-ZEV.png"
                alt="ZEV"
                style={{ verticalAlign: "middle", marginRight: "5px" }}
                width={500}
                height={450}
                quality={100}
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="about-content">
              <div className="content">
                <span className="sub-title">
                  <StaticImage
                    src="../../assets/images/sun-icon.jpg"
                    alt="sunIcon"
                    style={{ verticalAlign: "middle", marginRight: "5px" }}
                    width={32}
                    height={32}
                  />
                  {t("ZEV")}
                </span>
                <h2>{t("Zusammenschluss zum Eigenverbrauch")}</h2>
                <p>{t("Home - ZEV - Text - 1")}</p>
                <ul className="about-list">
                  <li>
                    <i className="flaticon-tick"></i>
                    {t("Home - ZEV - List - 1")}
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    {t("Home - ZEV - List - 2")}
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    {t("Home - ZEV - List - 3")}
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    {t("Home - ZEV - List - 4")}
                  </li>
                </ul>
                <p>{t("Home - ZEV - Text - 2")}</p>
                <Link to="/zev" className="default-btn">
                  <i className="flaticon-right"></i>
                  {t("Weiterlesen")} <span></span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZEV;

import React from "react";
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import Layout from "../components/App/Layout";
import MainBanner from "../components/Index/MainBanner";
import FeaturedService from "../components/Index/FeaturedService";
import Zev from "../components/Index/ZEV";
import { graphql } from "gatsby";
import WasserWaerme from "../components/Index/WasserWaerme";
import Emobility from "../components/Index/EMobility";
import LoadOptimization from "../components/Index/LoadOptimization";
import WashingMachine from "../components/Index/WashingMachine";
import InfoBanner from "../components/Index/InfoBanner";
import { Seo } from "../components/App/SEO";

const Home = ({ data }) => {
  return (
    <Layout>
      <Navbar />
      <MainBanner />
      <FeaturedService />
      {data.info.edges.length > 0 ? (
        <InfoBanner info={data.info.edges[0].node} />
      ) : (
        <></>
      )}
      <Zev />
      <WasserWaerme />
      <Emobility />
      <LoadOptimization />
      <WashingMachine />
      <Footer />
    </Layout>
  );
};

export default Home;

export const query = graphql`
  query ($language: String!) {
    info: allMarkdownRemark(
      filter: {
        frontmatter: { language: { eq: $language }, visible: { eq: true } }
        fileAbsolutePath: { regex: "/(/infos/)/" }
      }
    ) {
      edges {
        node {
          excerpt(pruneLength: 250)
          frontmatter {
            visible
            title
            attachments {
              publicURL
            }
          }
        }
      }
    }
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => (
  <Seo
    title="Blockstrom AG – ZEV Beratung, Messung und Abrechnung"
    description="Beratung, Projektmanagement, Messung und Abrechnung Ihres Zusammenschlusses zum Eigenverbrauch (ZEV) zur effektiven Nutzung des Solarstrompotenzials"
  />
);

import React, { useEffect } from "react";
import ReactWOW from "react-wow";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";

const MainBanner = () => {
  useEffect(() => {
    const navbarHeight = document.getElementById("navbar").offsetHeight;

    const bannerSection = document.querySelector(".banner-section");
    bannerSection.style.marginTop = navbarHeight + "px";
  }, []);
  const { t } = useTranslation();
  return (
    <>
      <div className="banner-section">
        <StaticImage
          src="../../assets/images/main-banner/main-banner-blockstrom.jpg"
          alt="Banner Image"
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            zIndex: "-1",
          }}
        />
        <div className="overlay">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-5 col-md-12">
                <div className="main-banner-content">
                  <ReactWOW delay=".1s" animation="fadeInLeft">
                    <h1>Blockstrom</h1>
                    <h2>{t("Wir machen Energiedaten digital")}</h2>
                  </ReactWOW>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainBanner;

import React from "react";

const InfoBanner = ({ info }) => {
  return (
    <section className="info-area bg-image ptb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-sm-12 col-12">
            <div className="single-info-item">
              <h3>{info.frontmatter.title}</h3>
              <a href={info.frontmatter.attachments.publicURL} download>
                {info.excerpt}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InfoBanner;

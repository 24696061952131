import React from "react";
import { Link } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { RiOrganizationChart } from "react-icons/ri";
import { FaRegLightbulb } from "react-icons/fa";
import { MdOutlineElectricMeter } from "react-icons/md";

const FeaturedService = () => {
  const { t } = useTranslation();
  return (
    <div className="boxes-area ptb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-boxes-item">
              <div className="icon">
                <FaRegLightbulb size={42} />
              </div>
              <h3>
                <Link to="/consulting">{t("Beratung")}</Link>
              </h3>
              <p>{t("Home - Service - Text - 1")}</p>

              <Link to="/consulting" className="default-btn">
                <i className="flaticon-right"></i>
                {t("Weiterlesen")} <span></span>
              </Link>

              <div className="shape1"></div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-boxes-item">
              <div className="icon">
                <RiOrganizationChart size={42} />
              </div>
              <h3>
                <Link to="/projectmanagement">{t("Projektmanagement")}</Link>
              </h3>
              <p>
                {t("Home - Service - Text - 2")}
                <b />
              </p>

              <Link to="/projectmanagement" className="default-btn">
                <i className="flaticon-right"></i>
                {t("Weiterlesen")} <span></span>
              </Link>

              <div className="shape1"></div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-sm-3 offset-md-3">
            <div className="single-boxes-item">
              <div className="icon">
                <MdOutlineElectricMeter size={42} />
              </div>
              <h3>
                <Link to="/meterbilling">{t("Messung & Abrechnung")}</Link>
              </h3>
              <p>{t("Home - Service - Text - 3")}</p>

              <Link to="/meterbilling" className="default-btn">
                <i className="flaticon-right"></i>
                {t("Weiterlesen")} <span></span>
              </Link>

              <div className="shape1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedService;
